
:root {
    --border-size: 0.125rem;
    --duration: 250ms;
    --ease: cubic-bezier(0.215, 0.61, 0.355, 1);
    --font-family: monospace;
    --color-primary: white;
    --color-secondary: #1049A9;
    --color-tertiary: dodgerblue;
    --shadow: rgba(0, 0, 0, 0.1);
    --space: 1rem;
}

img {
    user-select: none;
}

.sticky {
    position: fixed !important;
    top: 0;
    width: 100%;
    z-index: 112;
}

.dot {
    height: 15px;
    width: 15px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    box-shadow: -2px 1px 55px 47px rgba(242,0,0,0.77);
    -webkit-box-shadow: -2px 1px 55px 47px rgba(242,0,0,0.77);
    -moz-box-shadow: -2px 1px 55px 47px rgba(242,0,0,0.77);
}

html, body {
    margin: 0px;
    width: 100%;
    overflow-x: hidden;
}

.back-green {
    background-color: #1049A9;
}

* {
    font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #1049A9;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #1049A9;
}

::-webkit-scrollbar-thumb {
    background-color: #535353;
}

#header_container {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid;
    width: 100%;
    height: auto;
    background: rgb(16,73,169);
    background: linear-gradient(148deg, rgba(16,73,169,1) 18%, rgba(0,50,138,1) 100%);
}

    #header_container.overflow {
        overflow: hidden;
        height: auto;
    }

.first_lvl_menu a, .second_lvl_menu a {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.why-us-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.resh-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px !important;
}

    .resh-box h2 {
        margin-top: 0;
        margin-bottom: 0;
    }

.resh-box-item h2 {
    margin-bottom: 0;
}

.resh-box h3 {
    margin-bottom: 0;
}

.header-logo {
    margin-left: -110px;
}

.second_lvl_menu a {
    font-size: 14px;
    text-transform: none;
    font-weight: normal;
}

.link.active {
    text-decoration: underline;
}

.container_header.absolute {
    height: 75px;
}

.container_header.relative {
    height: 100px;
}

.header_menu a:hover {
    text-decoration: underline;
}

.header_menu li:hover .second_lvl_menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

/*$dark-green-color;*/
.wrapper {
    width: 1280px;
    margin: auto;
}

h2 {
    margin-top: 50px !important;
    font-size: 60px !important;
    font-weight: bold !important;
}

h3 {
    font-weight: 100 !important;
    font-size: 45px !important;
    line-height: 49.5px !important;
}

h5 {
    font-weight: 400 !important;
    font-size: 25px !important;
}

.contacts-us-img {
    width: 50%;
}

@media (max-width: 1000px) {
    .about-us-box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .about-us-text {
        width: 100% !important;
        word-break: break-word;
    }

    .contacts-us-img {
        width: 100%;
    }

    .about-us-img {
        display: none;
    }

    .slider-element-img {
        display: none !important;
    }

    .slider-element-info h2 {
        font-size: 40px !important;
    }

    .slider-element-info p {
        font-size: 15px !important;
    }
}

@media (max-width: 1280px) {
    .wrapper {
        width: 100%;
    }
}

.white {
    background: white;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.header {
    max-width: 1440px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-flow: row;
    flex-flow: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 80px;
}

.container-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

    .container-body div {
        max-width: 1440px;
        width: 100%;
    }

.flexbox_wrap {
    width: auto;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.logo {
    min-width: 150px;
    height: 100%;
    background-size: 100% cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-left: -120px;
}

.header_menu ul {
    list-style-type: none;
    padding: 0px;
}

    .header_menu ul.second_lvl_menu {
        padding: 16px 0px;
    }

.header_menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    width: auto;
    display: block;
}

@media (max-width: 1080px) {
    .header-logo {
        margin-left: -200px;
    }

    .header_menu {
        display: none;
    }

    .first_lvl_menu {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .header_menu {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        align-items: center;
    }

    .header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: auto;
    }

    .service-text {
        text-align: center;
        line-height: 45px;
    }

    .need-help-info {
        text-align: center;
    }

        .need-help-info h2 {
            font-size: 36px !important;
        }

    #send-za {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 14vh;
    }

    .contant-flex-item {
        display: block !important;
    }

    .first_lvl_menu li {
        padding: 10px;
    }

    .service-text {
        padding-left: auto !important;
    }

    .skill-info h4 {
        word-break: break-word;
    }

    .skill-btn {
        font-size: 15px !important;
        padding: 14px;
    }

    h2 {
        font-size: 20px !important;
        max-width: 93vw;
    }

    .contact-item.right {
        margin-left: 10% !important;
    }

    .resh-box-img {
        display: none;
    }

    h3 {
        font-size: 35px !important;
    }

    h5 {
        font-size: 15px !important;
        max-width: 94vw;
    }
}

.first_lvl_menu {
    height: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0px;
}

    .first_lvl_menu li {
        height: 100%;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0px 15px 0px 15px;
    }

        .first_lvl_menu li a {
            display: block;
            height: auto;
            width: 100%;
            color: white;
        }

.footer-container__copyright .container_row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.second_lvl_menu {
    height: auto;
    display: none;
    position: absolute;
    z-index: 101;
    /*margin: -20px -25px 0px -70px;*/
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-left: 50%;
    margin-top: -10px;
    /*margin-top: -20px;*/
    top: 100%;
    background: #3a3c3e;
    -webkit-box-shadow: 1px 1px 22px -11px black;
    box-shadow: 1px 1px 22px -11px black;
}

    .second_lvl_menu li {
        height: auto;
        width: 150px;
        margin: 0px;
        padding: 4px 20px;
    }

        .second_lvl_menu li a {
            display: block;
            height: auto;
            color: white;
        }

.send_button {
    margin-left: 25px;
    margin-right: 25px;
}

.second_lvl_menu:before {
    content: '';
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    top: -11px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11px 11px 11px;
    border-color: transparent transparent #3a3c3e transparent;
}

.main-content {
    height: 80px;
    width: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 0px;
}

    .main-content #sidebar-toggle {
        border-radius: 3px;
        display: block;
        position: relative;
        padding: 10px 7px;
        float: right;
    }

        .main-content #sidebar-toggle .bar {
            display: block;
            width: 18px;
            margin-bottom: 3px;
            height: 2px;
            border-radius: 1px;
        }

            .main-content #sidebar-toggle .bar:last-child {
                margin-bottom: 0;
            }

        .main-content #sidebar-toggle .bar {
            display: block;
            width: 32px;
            margin-bottom: 5px;
            height: 3px;
            background-color: #3a3c3e;
            border-radius: 1px;
        }

            .main-content #sidebar-toggle .bar:nth-child(2) {
                width: 12px;
            }

            .main-content #sidebar-toggle .bar:nth-child(1) {
                width: 24px;
            }

            .main-content #sidebar-toggle .bar:last-child {
                margin-bottom: 0;
            }

.sidebar {
    position: absolute;
    z-index: 101;
    right: -240px;
    background: #3a3c3e;
    width: 240px;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-transition: right 0.4s ease-in-out;
    transition: right 0.4s ease-in-out;
}

#sidebar ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.open-sidebar {
    right: 0px;
}

#sidebar ul li {
    margin: 0;
}

    #sidebar ul li a.main {
        padding: 15px 20px 15px 35px;
        text-decoration: none;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: white;
        display: block;
        border-bottom: 1px solid #2d2f31;
        -webkit-transition: background 0.3s ease-in-out;
        transition: background 0.3s ease-in-out;
    }

.submenu a {
    padding: 15px 20px 15px 45px;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.05em;
    text-transform: none;
    color: white;
    display: block;
    border-bottom: 1px solid #2d2f31;
    -webkit-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
}

#sidebar li a:hover {
    background: #1049A9;
    color: white !important;
}

.menu a.home {
    display: inline-block;
}

.menu a.explore {
    display: inline-block;
}

.menu a.users {
    display: inline-block;
}

.menu a.signout {
    display: inline-block;
}

#sidebar .menu .submenu {
    display: none;
}

#sidebar ul li ul.visible {
    display: block;
}

.submenu a.home {
    display: inline-block;
}

.submenu a.users {
    display: inline-block;
}

.submenu a.signout {
    display: inline-block;
}

.flex-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.submenu li {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.05em;
    font-size: 14px;
    text-transform: none;
    font-weight: normal;
}

.app_on_licence {
    width: 100%;
    height: 66vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: #1049a9;
}

@media (min-width: 781px) {
    #drop_menu_wrapper {
        display: none;
    }

    .img-deploy {
        max-width: 100%;
    }
}

@media (max-width: 781px) {
    #drop_menu_wrapper {
        display: block;
    }

    .header-info {
        display: none;
    }
}

.overflow-hidden__box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #5a5a5a;
}

.overflow-hidden__box::-webkit-scrollbar {
    width: 6px;
    background-color: #535353;
}

.overflow-hidden__box::-webkit-scrollbar-thumb {
    background-color: #1049A9;
}

.system-logo {
    margin: 5px;
}

.crm-systems-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

    .crm-systems-list div {
        height: 255px;
        width: 255px;
        margin-top: -84px;
    }

.slick-slider {
    width: 100%;
    height: calc(97vh - 150px);
    background: rgb(16,73,169);
    background: linear-gradient(148deg, rgba(16,73,169,1) 18%, rgba(0,50,138,1) 100%);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.slider-element {
    height: auto !important;
    min-height: 0px;
    display: flex !important;
    flex-direction: row-reverse;
}

.slick-dots li button:before {
    font-size: 22px;
}

.slick-prev:before {
    content: '❮';
}

.slick-next:before {
    content: '❯';
}

.slider-element-img {
    height: 100% !important;
    width: 115%;
}

.slick-slide {
    height: 100% !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.slick-list {
    height: 100% !important;
}

.slick-track {
    height: 100% !important;
}

.slider-element-info {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 5%;
    padding-right: 3%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.slider-element-info-button-area .buttons-statistic-item ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 10%;
}

.slider-element-info h2 {
    font-size: 50px !important;
    color: white;
    margin-bottom: 40px;
}

.slider-element-info p {
    color: white;
    letter-spacing: 4px;
    padding-right: 10%;
    font-size: 25px;
}

#footer {
    background: rgb(16,73,169);
    background: linear-gradient(148deg, rgba(16,73,169,1) 18%, rgba(0,50,138,1) 100%);
}

.footer-container {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-top: 75px;
    padding-bottom: 45px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.service-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.service-flexitem {
    width: calc( ( 100% / 3) - 62px);
    padding: 90px 30px 100px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #e5e7e9;
}

    .service-flexitem h5 {
        text-transform: capitalize;
        font-family: 'Roboto',sans-serif;
        margin: 0px;
        margin-bottom: 13px;
        margin-top: 14px;
    }

    .service-flexitem a {
        text-decoration: none;
        color: black;
    }

.service-infotext p {
    font-size: 14px;
    letter-spacing: 0.35px;
    padding-bottom: 15px;
    text-align: center;
    font-family: 'Roboto',sans-serif;
    margin: 0px;
    max-width: 350px;
}

.footer-logo {
    display: block;
    background-size: 100% cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 35px;
    padding-bottom: 20px;
}

.swiper-container {
    width: 100%;
    height: 100vh;
    min-height: 300px;
    max-height: 600px;
}

.flex-item {
    min-width: 270px;
    max-width: 270px;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.footer-text-bottom {
    font-size: 14px;
    font-family: 'Roboto',sans-serif;
    color: #9b9b9b;
    padding-bottom: 20px;
    letter-spacing: 0.35px;
    line-height: 24px;
}

    .footer-text-bottom p {
        margin: 0px;
    }

.link-bottom {
    display: block;
    text-decoration: none;
    color: white;
    font-family: 'Roboto',sans-serif;
    font-weight: bold;
    margin: 0px;
    -webkit-transition: .5s all ease;
    transition: .5s all ease;
}

    .link-bottom:hover {
        color: #37ca8a;
        -webkit-transition: .5s all ease;
        transition: .5s all ease;
    }

.footer_titlebox {
    text-transform: uppercase;
    color: white;
    font-family: 'roboto',sans-serif;
    font-weight: bold;
    font-size: 14px;
}

    .footer_titlebox h3 {
        margin: 0px 0px 30px 0px;
        padding: 0px;
    }

.board {
    border-top: 1px solid gray;
    width: 100%;
    padding-bottom: 33px;
}

.footer-container__copyright {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    font-family: 'Roboto',sans-serif;
    color: white;
    padding-bottom: 33px;
}

.container_row {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.recent_post_footer figure {
    margin-left: 0px;
    margin-top: 0px;
    width: 70px;
    height: 70px;
    margin-bottom: 23px;
    margin-right: 30px;
    border-radius: 3px;
    overflow: hidden;
}

.box_news_text__info {
    font-size: 12px;
    font-style: italic;
}

    .box_news_text__info a {
        text-decoration: none;
        color: #9b9b9b;
    }

.box_news_text__title {
    margin: 0px;
    color: white;
}

.link-menu_footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

    .link-menu_footer li {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        margin-top: 15px;
    }

        .link-menu_footer li a:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f105";
            font-size: 18px;
            line-height: 19px;
            padding-right: 10px;
            padding-left: 0;
            color: #37ca8a;
        }

        .link-menu_footer li:nth-child(1) {
            margin-top: 0px;
        }

        .link-menu_footer li:nth-child(2) {
            margin-top: 0px;
        }

        .link-menu_footer li a.active {
            color: #37ca8a;
        }

        .link-menu_footer li a {
            text-decoration: none;
            color: white;
            font: 400 14px/19px 'Roboto',sans-serif;
            color: #fff;
        }

.contant-flex-item {
    color: white;
}

    .contant-flex-item p span {
        color: white;
        letter-spacing: 0.35px;
        font-family: 'Roboto',sans-serif;
        font-size: 14px;
        margin-left: 15px;
    }

    .contant-flex-item p a {
        text-decoration: none;
        color: white;
        letter-spacing: 0.35px;
        font-family: 'Roboto',sans-serif;
        font-size: 14px;
        margin-left: 15px;
    }

.follow_links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

    .follow_links ul {
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

        .follow_links ul li {
            margin-left: 10px;
        }

            .follow_links ul li a {
                color: white;
                font-size: 24px;
                text-decoration: none;
            }

.flexbox-item_header-info {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    color: white;
    padding-left: 30px;
}

    .flexbox-item_header-info p {
        margin-right: 35px;
        font-weight: 400;
        font-family: 'Roboto',sans-serif;
        font-size: 14px;
    }

        .flexbox-item_header-info p svg {
            color: #37ca8a;
            margin-right: 10px;
        }

.copyright_text {
    padding-left: 15px;
}

@media (max-width: 1072px) {
    .header-info_container {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column;
        flex-flow: column;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .flexbox-item_header-info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column;
        flex-flow: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media (min-width: 1072px) {
    .contant-flex-item {
        display: block;
    }

    .header-info_container {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.header-link__social {
    margin: 0px;
    padding: 0px 15px 0px 0px;
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

    .header-link__social li {
        margin-left: 10px;
    }

        .header-link__social li a {
            color: white;
            font-size: 24px;
            text-decoration: none;
        }

.header-info {
    background-color: #3a3c3e;
}

.overflow-hidden__box {
    height: calc(100%-80px);
    overflow: hidden;
}

#body_overflow {
    position: relative;
    overflow: hidden;
}

.black {
    color: black;
}

.need-help-box {
    min-height: calc(100vh - 70vh);
}

    .need-help-box .flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        min-height: calc(100vh - 70vh);
    }

.need-help-info {
    padding-top: 5%;
}

.need-help-box .need-help-info h2 {
    margin: 0;
}

.need-help-box .need-help-info h3 {
    margin-bottom: 0;
}

.box-info-flex {
    height: auto;
    width: 100%;
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 95px 15px 0px 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.box-statistic-flex {
    height: auto;
    width: 100%;
    background-color: #3a3c3e;
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 95px 15px 0px 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.box-statistic {
    height: 600px;
    background-color: #1049A9;
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
}

    .box-statistic::after {
        content: "";
        height: 45px;
        width: 100%;
        position: absolute;
        background-color: white;
        top: -1px;
    }

    .box-statistic::before {
        content: "";
        height: 28px;
        width: 100%;
        position: absolute;
        background-color: white;
        bottom: 0px;
    }

.header-statistic-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 24px;
}

.header-info-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 24px;
}

    .header-info-item h6 {
        font-family: 'Roboto', sans-serif;
        color: #74787c;
        font-size: 24px;
        margin: 0px;
        margin-bottom: 5px;
        letter-spacing: 0.24px;
        line-height: 28.8px;
    }

    .header-info-item h3 {
        font-family: 'Roboto', sans-serif;
        line-height: 49.5px;
        margin: 0px;
    }

    .header-info-item h2 {
        font-family: 'Roboto', sans-serif;
        line-height: 49.5px;
        margin: 0px;
    }

    .header-info-item span {
        font-weight: bold;
    }

    .header-info-item p {
        letter-spacing: 0.35px;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        margin: 0px;
        line-height: 30px;
    }

.header-statistic-item h6 {
    font-family: 'Roboto', sans-serif;
    color: #74787c;
    font-size: 24px;
    margin: 0px;
    margin-bottom: 5px;
    letter-spacing: 0.24px;
    line-height: 28.8px;
}

.header-statistic-item h3 {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-size: 45px;
    line-height: 49.5px;
    margin: 0px;
}

.header-statistic-item span {
    font-weight: bold;
}

.header-statistic-item p {
    letter-spacing: 0.35px;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    margin: 0px;
    line-height: 30px;
}

.buttons-statistic-item {
    margin-top: 15px;
}

    .buttons-statistic-item ul {
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

        .buttons-statistic-item ul li:nth-child(2) {
            margin-left: 20px;
            margin-right: 20px;
            text-transform: lowercase;
            font-family: 'Roboto',sans-serif;
            font-style: italic;
            color: white;
        }

        .buttons-statistic-item ul li a {
            padding: 15px 40px;
            display: block;
            text-decoration: none;
            background: #1049A9;
            font-weight: bold;
            color: white;
            text-transform: uppercase;
            font-family: 'Roboto',sans-serif;
            border: 2px solid #1049A9;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: 0.5s;
            transition: 0.5s;
        }

.work-steps-box {
    background: rgb(16,73,169);
    background: linear-gradient(148deg, rgba(16,73,169,1) 18%, rgba(0,50,138,1) 100%);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.buttons-hover-text-blue a:hover {
    color: #1049A9;
    border-color: #1049A9;
}

.buttons-hover-text-blue button:hover {
    color: #1049A9;
    border-color: #1049A9;
}

.buttons-statistic-item ul li a:hover {
    padding: 15px 40px;
    display: block;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Roboto',sans-serif;
    border: 2px solid white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: 0 0;
}

.number-statistic-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.lasted-news-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.number-item {
    position: relative;
    width: 270px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 48px;
    margin-top: 35px;
}

.number-item_value {
    font-family: 'Roboto',sans-serif;
    font-size: 75px;
    font-weight: bold;
    color: #1049A9;
}

.number-item_introtext {
    font-family: 'Roboto',sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.lasted-news_textnews {
    padding: 0px 25px 20px 25px;
}

.backslash::after {
    content: "";
    position: absolute;
    height: 130px;
    background-color: #494a4b;
    width: 1px;
    right: 0px;
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
}

.anons-image-box {
    margin-bottom: 23px;
}

.successfull-case-section {
    padding: 30px 0px;
}

.successfull-case-box {
    width: 100%;
    background-color: #3b3c40;
    background-image: url(./img/blog-thumb2.jpg);
    height: 604px;
    background-repeat: no-repeat;
    background-size: 50% 100%;
    background-position: right;
    margin: 0px;
    padding: 60px 0px 70px 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.successfull-case-main {
    height: 500px;
}

.overline {
    background-color: #595654;
    height: 1px;
    width: 50%;
    margin: auto;
}

/* ����� ��� ������ ������� */
.tab-header {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

/* ����� ��� ������� */
.tab-header__item {
    padding: 10px;
    margin: 5px;
    width: 28%;
    text-align: center;
}

/* ����� ��� ���������� � ���������� �������. �� ��������� �����. */
.tab-content__item {
    display: none;
}

    /* ����� ��� ��������� ���������� ������� */
    .tab-content__item.active {
        display: block;
    }

.successfull-case__text-award {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.tab-content__item.active span {
    color: gray;
    margin-right: 20px;
}

.successfull-case__button-award {
    display: block;
    text-align: center;
    text-decoration: none;
    padding: 14px 56px;
    width: 190px;
    color: white;
    background-color: #1049A9;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    margin: 10px;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
}

    .successfull-case__button-award:hover {
        color: #1049A9;
        background-color: black;
    }

.box-text-award {
    overflow: auto;
    height: 300px;
}

.tab-content__item.active p {
    margin: 0px;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-stretch: 100%;
    font-style: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    -webkit-font-variant-ligatures: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-weight: 400;
    height: 99px;
    letter-spacing: 0.35px;
    line-height: 24px;
}

.successfull-case-main h5 {
    text-align: center;
    text-transform: uppercase;
    font-family: 'Roboto',sans-serif;
    font-weight: bold;
    color: white;
    line-height: 55.3815px;
    font-size: 45px;
    margin: 0px 0px 20px 0px;
    padding: 0px;
}

    .successfull-case-main h5 span {
        font-weight: 200;
    }

.successfull-case-title {
    width: 50%;
    padding: 0px 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.container_about_title h1 {
    font-family: 'Roboto', sans-serif;
    font-stretch: 100%;
    font-style: normal;
    height: auto;
    line-height: 70.8px;
    text-transform: uppercase;
    margin: 0px;
}

.box-about-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 527px;
}

.box-imgages__man {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    height: 100%;
    width: 50%;
}

.box_text__whychooseus {
    width: 50%;
}

.container-about-wrapper {
    height: 220px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 100px;
    background-image: url(./img/slider3.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: white;
    /*filter: grayscale(100%);*/
}

.box_text__whychooseus h5 {
    font-family: 'Roboto', sans-serif;
    height: auto;
    line-height: 55.3815px;
    margin: 0px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.box_text__whychooseus p {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    letter-spacing: 0.35px;
    line-height: 24px;
    padding-bottom: 24px;
}

.box_text__whychooseus h5 span {
    font-weight: bold;
}

/*padding: 90px 0px 100px 0px;*/
.button_whychooseus {
    padding: 15px 40px;
    display: block;
    text-decoration: none;
    background: #1049A9;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 189px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 700;
    height: auto;
    line-height: 24px;
}

    .button_whychooseus:hover {
        padding: 15px 40px;
        display: block;
        text-decoration: none;
        font-weight: bold;
        color: #1049A9;
        text-transform: uppercase;
        font-family: 'Roboto',sans-serif;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: black;
    }

.section_our_advantages {
    height: auto;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.box-backgroundcolor-advantages {
    background-color: #3a3c3e;
    width: 50%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: auto;
    overflow: hidden;
    padding: 90px 0px 100px 0px;
}

.box-background-advantages {
    height: auto;
    background-image: url(./img/slider2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 50%;
}

.box-text-advantages {
    height: auto;
    overflow: hidden;
    width: 100%;
    margin: auto;
    z-index: 10;
}

.box-contant-text-advantages {
    width: 75%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

    .box-contant-text-advantages h3 {
        text-align: center;
        color: white;
        display: block;
        font-family: 'Roboto', sans-serif;
        font-size: 45px;
        font-weight: 100;
        height: 55px;
        line-height: 55.3815px;
        margin: 0px 0px 20px 0px;
    }

        .box-contant-text-advantages h3 span {
            font-weight: bold;
        }

    .box-contant-text-advantages ul {
        margin: 0px;
        padding: 0px;
        list-style-type: none;
    }

        .box-contant-text-advantages ul li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            color: #1049A9;
        }

.text-advantages {
    max-width: 390px;
    margin-left: 30px;
}

    .text-advantages a {
        text-decoration: none;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-weight: 700;
        height: auto;
        line-height: 29.5368px;
        margin-bottom: 20px;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
    }

        .text-advantages a:hover {
            color: #1049A9;
        }

    .text-advantages p {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        height: 96px;
        letter-spacing: 0.35px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.5);
        padding-bottom: 24px;
    }

.meter {
    height: 12px;
    /* Can be anything */
    position: relative;
    background: #555;
    border-radius: 3px;
}

    .meter > span {
        border-radius: 3px;
        display: block;
        height: 100%;
        background-color: #abcc37;
        position: relative;
        overflow: hidden;
    }

.orange > span {
    background-color: #f5bf2f;
}

.red > span {
    background-color: #f54b0f;
}

.blue > span {
    background-color: #79ccf2;
}

.box-text-processbar-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.flexitem-text {
    width: 50%;
    margin: 15px;
}

    .flexitem-text p {
        margin: 0px;
        padding: 0px;
        font-family: 'Roboto',sans-serif;
        font-size: 14px;
        height: 96px;
        letter-spacing: 0.35px;
        line-height: 24px;
    }

.flexitem-processbar {
    width: 50%;
    margin-left: 15px;
}

.box_who_we_are h3 {
    margin: 0px;
    padding: 0px;
    font-family: 'Roboto',sans-serif;
    height: 55px;
    line-height: 55.3815px;
    margin-bottom: 20px;
}

    .box_who_we_are h3 span {
        font-weight: bold;
    }

.progress-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.progress-header {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    height: 24px;
    line-height: 24px;
}

.processbar-item-skills {
    margin-bottom: 25px;
}

.section_who_we_are {
    padding: 90px 0px 55px 0px;
}

.section_consulting {
    background-color: #1049A9;
}

.box-consulting {
    padding-top: 90px;
    padding-bottom: 90px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.text-consulting {
    font-family: 'Roboto', sans-serif;
    font-size: 65px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 79.9955px;
    color: white;
    margin: 0px;
    padding: 0px;
}

.item-consulting a {
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-stretch: 100%;
    font-weight: 900;
    letter-spacing: 0.75px;
    line-height: 18px;
    color: white;
    padding: 20px 70px;
    border: 3px solid white;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

    .item-consulting a:hover {
        background-color: black;
        color: #1049A9;
    }

.box-flexitem-contact_address {
    width: 30%;
    padding: 0px 20px 0px 20px;
}

.flexitem-contact-address_icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

    .flexitem-contact-address_icon p {
        padding: 0px;
        margin: 0px;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        height: 24px;
        line-height: 24px;
        margin-left: 10px;
    }

.flexitem-contact-address {
    margin-bottom: 24px;
}

    .flexitem-contact-address h5 {
        margin: 0px;
        font-family: 'Roboto', sans-serif;
        height: 40px;
        line-height: 40.6131px;
        margin-bottom: 13px;
    }

.container-flexbox-adress_froms {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-bottom: 60px;
}

.container-contacts_map {
    margin-bottom: 30px;
    margin-top: 60px;
}

.flexitem-contact_forms {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
}

.input-write-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.message_textarea {
    resize: none;
    width: 100%;
    height: 220px;
}

.input-write-text input {
    display: block;
    width: 216px;
    max-width: 100%;
    height: 52px;
    background: 0 0;
    border: none;
    border-bottom: 1px solid #dedede;
    color: #000;
    font-family: 'Roboto',sans-serif;
    font-size: 14px;
    line-height: 24px;
    padding: 0px;
    margin: 0px;
    vertical-align: middle;
    outline: none;
    margin-bottom: 40px;
}

    .input-write-text input:focus {
        border-color: #000;
    }

.message_textarea {
    display: block;
    width: 100%;
    height: 144px;
    background: 0 0;
    border: none;
    border-bottom: 1px solid #dedede;
    color: #000;
    font-family: 'Roboto',sans-serif;
    font-size: 14px;
    line-height: 24px;
    padding: 0px;
    margin: 0px;
    vertical-align: middle;
    outline: none;
    margin-bottom: 40px;
}

    .message_textarea:focus {
        border-color: #000;
    }

.button-message-send {
    border: 0px;
    outline: none;
    background-color: #1049A9;
    color: white;
    padding: 14px 60px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-stretch: 100%;
    font-weight: 700;
    height: 52px;
    letter-spacing: normal;
    line-height: 24px;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

    .button-message-send:nth-child(2) {
        margin-left: 5px;
    }

    .button-message-send:hover {
        background-color: black;
    }

.form-message_title {
    font-family: 'Roboto', sans-serif;
    font-size: 33px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
    padding: 0px;
    margin-bottom: 40px;
}

.container-list-news_title {
    text-align: center;
}

.container-list-news_title {
    font-family: 'Roboto', sans-serif;
    font-size: 45px;
    font-weight: 700;
    height: 55px;
    line-height: 55.3815px;
}

.box-flex-list-news {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flexitem-list-news {
    width: 40%;
    padding: 15px;
    margin: 0px 15px;
}

    .flexitem-list-news img {
        display: block;
        margin: auto;
        margin-bottom: 23px;
    }

.list-news_tags {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 24px;
}

    .list-news_tags li {
        margin: 5px;
    }

        .list-news_tags li a {
            display: block;
            text-decoration: none;
            padding: 5px;
            background-color: black;
            color: white;
            -webkit-transition: all .3s ease;
            transition: all .3s ease;
        }

            .list-news_tags li a:hover {
                color: #1049A9;
            }

.container-list-news {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 60px;
}

.list-news_linkfull_title {
    text-align: center;
    text-decoration: none;
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: 33px;
    font-weight: 700;
    line-height: 40.6131px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

    .list-news_linkfull_title:hover {
        color: #1049A9;
    }

    .list-news_linkfull_title h5 {
        margin: 0px;
        padding: 0px;
        margin-bottom: 13px;
    }

.list-news_info {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    height: auto;
    line-height: 24px;
    text-align: right;
    margin: 0px;
    padding: 0px;
}

.list-news_shorttext {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    letter-spacing: 0.35px;
    line-height: 24px;
    text-align: center;
}

.list-news_readfull {
    text-align: center;
    text-decoration: none;
    background-color: #37ca8a;
    color: white;
    padding: 13px 54px;
    cursor: pointer;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

    .list-news_readfull:hover {
        background: #000;
        color: #1049A9;
    }

.box-list-news_button-readfull {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.box-list-news_pagination {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
}

    .box-list-news_pagination li {
        margin: 0px 2px;
    }

        .box-list-news_pagination li a {
            display: block;
            padding: 7px 13px;
            text-decoration: none;
            color: white;
            background-color: #1049A9;
            -webkit-transition: all .3s ease;
            transition: all .3s ease;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 24px;
        }

            .box-list-news_pagination li a:hover {
                color: #1049A9;
                background-color: black;
            }

            .box-list-news_pagination li a.active {
                background-color: black;
            }

.container-services_title-page {
    text-align: center;
}

    .container-services_title-page h5 {
        margin: 0px;
        font-family: 'Roboto', sans-serif;
        font-size: 45px;
        font-weight: 100;
        margin-bottom: 20px;
    }

        .container-services_title-page h5 span {
            font-weight: bold;
        }

.tab-header__services {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.container-services_text-page p {
    margin: 0px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
}

.box-button_tab-services {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 1px solid #dedede;
    padding: 24px 80px 24px 30px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    position: relative;
}

.box-button_text-services {
    border-bottom: 1px;
    border-left: 1px;
    border-right: 1px;
    border-top: 0px;
    border-style: solid;
    border-color: #dedede;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px 0px;
    padding: 30px 24px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

    .box-button_text-services p {
        margin: 0px;
        color: #a3a3a3;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        letter-spacing: 0.35px;
        line-height: 24px;
        margin-bottom: 14px;
        text-indent: 20px;
    }

.tab-header__item__services {
    margin-bottom: 30px;
    border-radius: 5px;
}

.button-open-tab_service {
    display: block;
    height: 100%;
    width: 100%;
    font-weight: bold;
    text-decoration: none;
    color: black;
    cursor: pointer;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    position: relative;
}

.box-button_tab-services::after {
    content: '\f00c';
    height: 100%;
    width: 70px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #1049A9;
    font-family: fontawesome;
    font-size: 15px;
    font-weight: 100;
    height: 70px;
    line-height: 70px;
    position: absolute;
    right: 0px;
    text-align: center;
    color: white;
}

.tabs_service {
    margin-top: 48px;
}

.container-services {
    padding-top: 90px;
    padding-bottom: 60px;
}

.step-img {
    height: 100px;
    width: 100px;
}

.work-steps-step {
    width: 35%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

    .work-steps-step p {
        text-align: center;
    }

    .work-steps-step h3 {
        margin-bottom: 0;
    }

.work-steps-multi-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    border-radius: 20px;
    padding: 28px;
    width: 100%;
}

.work-steps-header {
    margin-bottom: 35px;
    margin-top: 35px;
}

.about-us-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.about-us-img {
    width: 50%;
}

.about-us-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 5%;
    width: 50%;
    padding-top: 2%;
}

    .about-us-text h3 {
        letter-spacing: 2.5px;
    }

.text-italic {
    font-style: italic;
}

.clients-list-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

    .clients-list-box h3 {
        margin-bottom: 0;
    }

.client-img {
    margin: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 200px;
    width: 200px;
}

.flex-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.service-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.service-img {
    border-radius: 50% 10%;
    width: 500px;
    height: 500px;
    margin-left: 10%;
    margin-top: 100px;
    margin-bottom: 100px;
}

.service-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 5%;
    padding-top: 10px;
    width: 50%;
}

    .service-text h2 {
        font-size: 45px;
        font-weight: 400;
    }

    .service-text h3 {
        letter-spacing: 2.5px;
    }

.service-par h3 {
    font-size: 40px;
    font-weight: 100;
    margin: 0;
}

.service-par .line {
    width: 10%;
    height: 6px;
    background: #1049A9;
}

.skill-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.skill-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-bottom: 30px;
}

.multi-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-shadow: var(--shadow) 4px 4px;
    box-shadow: var(--shadow) 4px 4px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

    .multi-button button {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        cursor: pointer;
        position: relative;
        padding: calc(var(--space) / 1.125) var(--space) var(--space);
        border: var(--border-size) solid black;
        color: var(--color-secondary);
        background-color: var(--color-primary);
        font-size: 1.5rem;
        font-family: var(--font-family);
        text-shadow: var(--shadow) 2px 2px;
        -webkit-transition: flex-grow var(--duration) var(--ease), -webkit-box-flex var(--duration) var(--ease);
        transition: flex-grow var(--duration) var(--ease), -webkit-box-flex var(--duration) var(--ease);
        transition: flex-grow var(--duration) var(--ease);
        transition: flex-grow var(--duration) var(--ease), -webkit-box-flex var(--duration) var(--ease), -ms-flex-positive var(--duration) var(--ease);
    }

        .multi-button button + button {
            border-left: var(--border-size) solid black;
            margin-left: calc(var(--border-size) * -1);
        }

        .multi-button button:hover,
        .multi-button button:focus {
            -webkit-box-flex: 2;
            -ms-flex-positive: 2;
            flex-grow: 2;
            color: white;
            outline: none;
            text-shadow: none;
            background-color: var(--color-secondary);
        }

        .multi-button button:focus {
            outline: var(--border-size) dashed var(--color-primary);
            outline-offset: calc(var(--border-size) * -3);
        }

    .multi-button:hover button:focus:not(:hover) {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        color: var(--color-secondary);
        background-color: var(--color-primary);
        outline-color: var(--color-tertiary);
    }

    .multi-button button:active {
        -webkit-transform: translateY(var(--border-size));
        transform: translateY(var(--border-size));
    }

.contact-item.right {
    margin-left: 30%;
}

.resh-box-img {
    width: 180px;
    height: 180px;
    margin-right: 20px;
}

:root {
    --red: hsl(0, 78%, 62%);
    --cyan: hsl(180, 62%, 55%);
    --orange: hsl(34, 97%, 64%);
    --blue: hsl(212, 86%, 64%);
    --varyDarkBlue: hsl(234, 12%, 34%);
    --grayishBlue: hsl(229, 6%, 66%);
    --veryLightGray: hsl(0, 0%, 98%);
    --weight1: 200;
    --weight2: 400;
    --weight3: 600;
}

.attribution {
    font-size: 11px;
    text-align: center;
}

    .attribution a {
        color: #3e52a3;
    }

@media (max-width: 400px) {
    h1 {
        font-size: 1.5rem;
    }
}

.box p {
    color: var(--grayishBlue);
}

.box {
    border-radius: 5px;
    -webkit-box-shadow: 0px 30px 40px -20px var(--grayishBlue);
    box-shadow: 0px 30px 40px -20px var(--grayishBlue);
    padding: 30px;
    margin: 20px;
    background-color: white;
}

.reguest-btn {
    animation: glowing 2s linear 0s infinite normal forwards;
    padding: 6px 17px;
    position: fixed;
    bottom: 0;
    margin: 30px;
    background-color: #1049A9 !important;
    font-size: 27px;
    border-radius: 5px;
    right: 0;
}

@keyframes glowing {
    0%, 50%, 100% {
        box-shadow: -2px 0px 69px 40px rgba(32,119,166,0.75);
    }

    25%, 75% {
        box-shadow: -2px -1px 81px 13px rgba(32,119,166,0.75);
    }
}

img {
    float: right;
}

@media (max-width: 450px) {
    .box {
        height: 200px;
    }
}

@media (max-width: 950px) and (min-width: 450px) {
    .box {
        text-align: center;
        height: 180px;
    }
}

.cyan {
    border-top: 3px solid var(--cyan);
}

.red {
    border-top: 3px solid var(--red);
}

.blue {
    border-top: 3px solid var(--blue);
}

.orange {
    border-top: 3px solid var(--orange);
}

h2 {
    color: var(--varyDarkBlue);
    font-weight: var(--weight3);
}

@media (min-width: 950px) {
    .row1-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .row2-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .box-down {
        position: relative;
        top: 150px;
    }

    .box {
        width: 340px;
    }

    .header p {
        width: 30%;
    }
}

@media (max-width: 1150px) {
    .service-img {
        display: none;
    }

    .service-text {
        width: auto;
        padding-left: 0px;
    }

    .need-help-img {
        display: none;
    }

    .need-help-info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}


    .modal-content h1, .modal-content h2, .modal-content h3 {
        text-align: center;
    }

    .modal-content h1 {
        font-size: 1.3em;
        text-transform: uppercase;
    }

    .modal-content h2 {
        font-size: 1.1em;
    }

    .modal-content h3 {
        font-size: .8em;
        letter-spacing: 2px;
    }

form {
    font-size: .8em;
}

.column {
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    padding: 10px;
}

    .column#main {
        -webkit-box-flex: 75%;
        -ms-flex: 75%;
        flex: 75%;
        padding: 50px;
        margin-top: 30px;
        margin-left: 15px;
    }

#secondary {
    background-color: #1049A9;
    border-radius: 0 4rem 4rem 0;
    text-align: center;
}

#main .form-control {
    border-radius: 0;
    font-size: .9em;
}

.sec-content h2 {
    color: white;
}

.sec-content h3 {
    color: white;
}

.sec-content button {
    color: white;
}

.btn {
    text-transform: uppercase;
    border-radius: .15rem;
    width: 200px;
    padding: .150rem .75rem;
    margin: 30px auto;
    font-family: 'PT Sans', sans-serif;
    letter-spacing: 2px;
}

.btn-primary {
    border-color: rgba(255, 255, 255, 0);
    background: #1049A9;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#1049A9), color-stop(99%, #37ca8a));
    background: linear-gradient(to right, #1049A9 0%, #37ca8a 99%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1da36', endColorstr='#fca86c',GradientType=1 );
    /* IE6-9 */
}

    .btn-primary:hover {
        border-color: rgba(255, 255, 255, 0);
    }

#main .btn-primary {
    width: 100%;
}

#secondary .btn-primary {
    background: #f8f9fa4f;
    color: #000;
}

.modal-body label {
    margin-bottom: 0;
}

.sec-content {
    margin-top: 85%;
}

.main-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px;
    position: relative;
}

.main-box-bg {
    background: url("./img/mainbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.1s ease;
    position: absolute;
    width: 100%;
    height: 100%;
}

.main-box-text {
    font-size: 42px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0;
}

    .main-box-text span {
        color: #1049A9;
        font-weight: 700;
    }

.we-do-box {
    display: flex;
    justify-content: center;
    max-width: 1000px !important;
    margin-top: 50px;
}

    .we-do-box div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .we-do-box p {
        font-size: 32px;
        text-align: center;
    }

#container_contant div {
    max-width: none;
}

.slick-slider {
    border-radius: 40px;
    padding: 50px;
    height: auto;
    background-color: #1049a9;
}

.slider-element-img {
    border-radius: 20px;
    max-height: 520px;
    height: auto !important;
}

#container_contant {
    margin-bottom: 100px;
}

.slider-element-info h2 {
    font-size: 42px !important;
    margin-top: 0 !important;
}

.slider-element-info p {
    color: white;
    letter-spacing: 2px;
    padding-right: 0;
}

.box-info-flex {
    box-shadow: none;
    padding: 50px;
    padding-bottom: 0;
}

.header-info-item h3 {
    font-size: 50px !important;
    font-weight: 500 !important;
    color: #343a40;
    text-align: center;
}

.work-steps-box {
    background-color: #1049a9;
    border-radius: 40px;
}


.header_menu {
    display: flex;
}

.header-btn {
    background: #fff;
    margin: 18px 0;
    border-radius: 10px;
    color: #1049a9;
    font-size: 20px;
    font-weight: 500;
    border: 2px solid #fff;
    transition: all 0.2s ease-in;
    text-transform: uppercase;
}

    .header-btn:hover {
        text-decoration: none !important;
        color: #fff;
        background: transparent;
    }

.column#main {
    margin-top: 0;
    margin-left: 0;
}

.modal-content h3 {
    letter-spacing: 1px;
    font-weight: 400 !important;
    font-size: 30px !important;
    margin-bottom: 25px;
}

.modal-body .btn-primary {
    color: #fff;
    background: #1049a9 !important;
    border: 2px solid #1049a9;
    transition: all 0.2s ease-in;
    padding: 15px;
    font-size: 18px;
    margin-bottom: 0;
}

    .modal-body .btn-primary:hover {
        color: #1049a9;
        background: #fff !important;
        border-color: #1049a9;
    }

.modal-body a {
    color: #1049a9;
}

.modal-open .modal {
    display: flex !important;
    align-items: center;
}

#main .form-control {
    border-radius: 5px;
}

.modal-body textarea {
    resize: none;
}

.work-steps-header {
    margin: 40px 0;
    color: #fff;
    font-size: 50px !important;
    font-weight: 500 !important;
}

@media (min-width: 950px) {
    .box {
        width: 400px !important;
    }
}

.projects-list img {
    width: 150px;
}

.projects-list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 50px;
}

.project-one, .project-two {
    max-width: 680px !important;
    display: flex;
    gap: 30px;
    border: 2px solid #1049a9;
    width: 48%;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.project-one {
    color: #fff;
    background: rgb(16,73,169);
    background: linear-gradient(148deg, rgba(16,73,169,1) 18%, rgba(0,50,138,1) 100%);
}

.box-info {
    padding: 150px 0;
}

.projects-list-item p {
    font-size: 16px;
}

.header-info-item p.projects-list-header {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 15px;
}

.project-two p {
    color: #1049a9 !important;
}

h1.service-heading {
    margin-top: 50px;
    text-align: center;
    font-size: 55px;
    font-weight: 600;
    color: #212529;
    margin-bottom: 20px;
}

h4.service-heading {
    text-align: center;
}

.service-text-blue, .service-text-white {
    border-radius: 20px;
    margin-top: 50px;
    padding: 50px;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.service-text-blue {
    background: rgb(16,73,169);
    background: linear-gradient(148deg, rgba(16,73,169,1) 18%, rgba(0,50,138,1) 100%);
    color: white;
}

.service-text-white {
    border: 3px solid #1049a9;
    color: #1049a9;
}

    .service-text-blue h2, .service-text-white h2 {
        margin-top: 0 !important;
        font-size: 45px !important;
        margin-bottom: 20px;
    }

        .service-text-blue h2{
            color: white;
        }

        .service-text-blue p, .service-text-white p {
            font-size: 18px;
        }

.skill-list {
    margin-top: 30px;
}

    .skill-list h3 {
        font-weight: 400 !important;
        font-size: 30px !important;
    }

.skill-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

    .skill-info div {
        width: 45%;
    }

.about-us-box img {
    width: 100%;
    border-radius: 20px;
}

.about-us-box {
    margin-top: 50px;
    gap: 50px;
}

.about-us-text, .why-us-text {
    width: 48% !important;
}

.about-us-text, .why-us-box {
    border: 3px solid #1049a9;
    border-radius: 20px;
    padding: 35px;
}

.about-us-box h2 {
    margin-top: 0 !important;
    font-size: 45px !important;
    margin-bottom: 25px;
}

.why-us-text {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.about-us-text p {
    font-size: 25px;
}

.why-us-box h5 {
    text-align: center;
}

.why-us-box ul {
    font-size: 20px;
}

.about-us-text h3 {
    font-weight: 500 !important;
    font-size: 35px !important;
    letter-spacing: normal;
}

.contact-item.right {
    margin-left: 0;
}

.contacts-div {
    display: flex;
    gap: 100px;
}

.contacts-us-img iframe {
    border-radius: 20px;
}

.header-logo_a {
    background: url(./img/logo.svg);
    height: 80px;
    background-position: center;
    width: 250px;
    background-repeat: no-repeat;
    background-size: cover;
}

.header-logo-en_a {
    background: url(./img/logo-en.svg);
    height: 80px;
    background-position: center;
    width: 250px;
    background-repeat: no-repeat;
    background-size: cover;
}

.contact-logo-img {
    background: url(./img/logo.svg);
    height: 80px;
    background-position: center;
    width: 250px;
    background-repeat: no-repeat;
    background-size: cover;
}

.contact-item a {
    color: #1049a9;
}

.contact-logo {
    background: #1049a9;
    border-radius: 20px;
    padding: 20px 0;
}

.about-us-text h3 {
    font-size: 50px !important;
}

.about-us-text h5 {
    font-size: 28px !important;
    color: #212529;
    opacity: 0.8;
}

#main .form-control {
    font-size: 18px;
}

.about-us-text {
    gap: 30px;
}

.about-us-page {
    gap: 0;
}

.slick-next {
    right: 20px !important;
}

.slick-prev {
    left: 10px !important;
}

    .slick-prev:before, .slick-next:before {
        font-size: 30px !important;
    }

#body_overflow {
    position: relative;
}

#body_bg {
    background: url(./img/bigBG.jpg);
    transition: all 0.1s ease;
    background-size: 1000px;
    background-repeat: round;
    position: absolute;
    width: 150%;
    height: 150%;
    z-index: -1;
}

.project-two, .about-us-page, .why-us-box, .service-text-white, .about-us-text {
    background: #fff;
}

.reveal {
    position: relative;
    opacity: 0;
}

    .reveal.active {
        opacity: 1;
    }

.active.fade-left {
    animation: fade-left 0.5s ease-in;
}

.active.fade-right {
    animation: fade-right 0.5s ease-in;
}

@keyframes fade-left {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-right {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.carousel {
    position: relative;
    display: flex;
}

    .carousel .slides {
        position: relative;
        left: 10em;
    }

        .carousel .slides img {
            position: absolute;
            height: 250px;
            margin-top: 60px;
            opacity: 0;
            z-index: 999;
            display: none;
        }

            .carousel .slides img.active {
                opacity: 1;
                display: block;
            }

    .carousel .overlays {
        position: relative;
        width: 10000px;
        height: auto;
    }

        .carousel .overlays .bar {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--bar-color);
            transform-origin: left;
            border-radius: 15px;
        }

    .carousel .nav-links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 0;
        padding: 0 6em 0 3em;
        list-style-type: none;
        cursor: pointer;
    }

        .carousel .nav-links .nav-link {
            font-size: 2.4rem;
            font-family: Lora, serif;
            line-height: 2;
            text-decoration: none;
            color: #7f8c8d;
            transition: 0.3s;
        }

            .carousel .nav-links .nav-link:hover {
                color: #f8f9fa;
            }

            .carousel .nav-links .nav-link.active {
                color: #f8f9fa;
                pointer-events: none;
            }

#header_container {
    z-index: 10000;
}

.bar p {
    color: #fff;
    width: 380px;
    right: 15px;
    position: absolute;
    height: 100%;
    align-content: center;
    font-size: 30px;
}

.modal-open #header_container {
    z-index: 1000;
}

button.close {
    position: absolute;
    right: 20px;
    top: 5px;
}

.close {
    font-size: 2.5rem;
    font-weight: 500;
    color: #001944;
}

.contacts-us-img {
    border: 3px solid #1049a9;
    border-radius: 20px;
}

.contact-item {
    display: flex;
    align-items: center;
}

    .contact-item img {
        width: 40px;
        margin-right: 15px;
        border-radius: 0;
    }

.about-us-text .etc_h5 {
    font-size: 25px !important;
    opacity: 0.8;
}

.modal-content h3 {
    font-size: 28px !important;
}

.contant-flex-item p a {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: 0;
}

.app-shop {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 40px;
}

.app-shop h2, .app-shop h4 {
    text-align: center;
}

.search-input {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.search-input input {
    height: 45px;
    width: 500px;
    border-radius: 10px;
    outline: none;
    border: 2px solid #1049A9;
    padding-left: 15px;
    font-size: 20px;
}

.search-input .rs-dropdown{
    width: auto;
}

.search-input .rs-btn {
    font-size: 20px;
    padding: 12px 15px;
    padding-right: 32px;
}

.search-input .rs-dropdown-toggle-caret {
    top: 12px;
}

.search-input .rs-dropdown-menu{
    font-size: 20px;
}

.search-input .rs-dropdown-item {
    padding: 12px 15px;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.category {
    width: 300px !important;
    height: 45px;
    font-size: 24px;
    border: 2px solid #1049A9;
    border-radius: 10px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.category-active {
    background: #1049A9;
    color: #fff;
}

.applications {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: flex-start;
}

.app-card {
    width: 48% !important;
    border: 2px solid #1049A9;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.app-card_img {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

.app-card_img img {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
}

.app-name {
    font-size: 26px;
}

.app-developer {
    font-size: 22px;
    color: #1049A9;
}

.app-description {
    font-size: 18px;
    color: #717273;
}

.app-installs {
    font-size: 18px;
}

.app-more {
    width: 100%;
    display: flex;
    height: 50px;
    font-size: 20px;
    background: #1049A9;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: #fff;
    transition: all 1s ease-out;
}

.app-more:hover{
    background: #2465d2;
    color: white;
    text-decoration: none;
}

.app-page-description {
    display: flex;
    gap: 50px;
}

.app-page-text h2 {
    font-size: 40px !important;
}

.app-page-text {
    font-size: 20px;
}

.app-page-text {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.app-page-text div {
    display: flex;
    justify-content: space-between;
}

a.app-page-button {
    border: 2px solid #1049A9;
    border-radius: 10px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

a.app-page-button.blue {
    color: white;
    background: #1049A9;
    margin-bottom: 20px;
}

a.app-page-button.white {
    color: #1049A9;
    background: white;
}

.select-lang {
    border-radius: 10px;
    outline: none;
    padding: 10px;
    width: 70px;
    color: white;
    text-transform: uppercase;
    background: none;
    border: none;
    font-size: 20px;
}

.showOnlyRu {
    width: auto !important;
    display: flex;
}

.showOnlyRu input{
    width: 20px;
    height: auto;
    margin-right: 10px;
    font-size: 18px;
}

.showOnlyRu label{
    margin-bottom: 0;
}

.main-text h3 {
    font-size: 40px !important;
    font-weight: 400 !important;
}

.main-text {
    font-size: 20px;
    margin-bottom: 40px;
    margin-top: 60px;
}

.app-dev-img img {
    height: 30px;
}

.app-dev-img {
    display: flex;
    justify-content: space-between;
}

.app-page-text img {
    height: 30px;
}

.app-img-slider {
    max-width: 680px !important;
    margin-top: 20px;
    background: rgb(16,73,169);
    background: linear-gradient(148deg, rgba(16,73,169,1) 18%, rgba(0,50,138,1) 100%);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.app-not-found {
    font-size: 25px;
    display: flex;
    justify-content: center;
    height: 80vh;
}

.app-not-found img{
    height: 100px;
}

.rs-picker-popup {
    z-index: 7000;
    margin-left: 10px;
    position: fixed;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #1049a9;
    font-size: 16px;
}

.select-phone {
    margin-bottom: 10px;
    margin-left: 10px;
}

.swiper-slide img {
    float: none;
}

.mySwiper .swiper-slide {
    width: 150px !important;
    height: 110px;
}

.mySwiper img {
    width: 150px;
    height: 110px;
    object-fit: cover;
    cursor: pointer;
}

.mySwiper2 .swiper-slide {
    height: 485px;
    padding-bottom: 20px;
    padding-left: 45px;
    padding-right: 45px;
}


.mySwiper2 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}

.swiper-button-prev, .swiper-button-next {
    width: auto !important;
}

.full-window {
    position: fixed;
    max-width: 100% !important;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 10000;
    margin-top: 0;
    background: #000000e3;
}

.full-window_img {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.full-window .mySwiper2 {
    height: 80%;
}

.full-window .mySwiper2 .swiper-slide {
    height: 100%;
    padding-bottom: 20px;
    padding-left: 60px;
    padding-right: 60px;
}

.full-window .mySwiper .swiper-slide {
    width: 250px !important;
    height: 150px;
}

.full-window .mySwiper img {
    width: 250px;
    height: 150px;
}

.button-back-to-shop {
    margin-top: 20px;
}

.button-back-to-shop a {
    color: #1049a9;
    font-size: 24px;
    font-weight: 600;
}

.app-page-text h2 {
    margin-top: 20px !important;
}

.main-text h3 {
    margin-top: 40px;
}

.vacancy h2, .vacancy h4 {
    text-align: center;
}

.vacancy .text {
    margin-top: 40px;
    font-size: 20px;
}

.vacancy h3 {
    font-weight: 400 !important;
    margin-top: 40px;
}

.vacancy-advantages {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 40px;
}

.vacancy-advantages-item {
    width: 49% !important;
    background: rgb(16,73,169);
    background: linear-gradient(148deg, rgba(16,73,169,1) 18%, rgba(0,50,138,1) 100%);
    border-radius: 10px;
    padding: 20px;
    color: #fff;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.vacancy-advantages-title {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
}

.vacancy-advantages-title h4 {
    font-size: 26px;
}

.vacancy-advantages-title img {
    width: 50px;
}

.vacancy ul {
    font-size: 20px;
    margin-top: 20px;
}

p.vacancy-request {
    font-size: 26px;
}

p.vacancy-request a {
    color: #1049a9;
}

.error-message {
    z-index: 100051;
}

.rs-toast-container {
    align-items: end !important;
    right: 25px;
}

#sale{
    display: none;
}

.img-deploy {
    width: 100%;
    object-fit: cover;
    height: 100%;
    max-width: 50%;
}

.notfound-box {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.notfound-box p{
    max-width: 500px;
}

.notfound-box a{
    width: 100%;
    display: flex;
    height: 50px;
    font-size: 20px;
    background: #1049A9;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: #fff;
    transition: all 1s ease-out;
    max-width: 500px;
}

@media (max-width: 1080px) {
    .main-box-bg {
        display: none;
    }

    .container-body {
        padding: 0 15px;
    }

    .main-box {
        padding: 50px 0;
    }

    .main-box-text {
        font-size: 30px;
        text-align: center;
    }

    .vacancy h2, .app-shop h2 {
        font-size: 24px !important;
    }

    .vacancy h4, .app-shop h4 {
        font-size: 20px !important;
    }

    .vacancy-advantages-item {
        width: 100% !important;
    }

    .about-us-box img {
        display: none;
    }

    .we-do-box {
        flex-wrap: wrap;
    }

        .we-do-box p {
            font-size: 25px;
        }

    .slider-element-info h2 {
        font-size: 32px !important;
    }

    .slick-slider br {
        display: none;
    }

    #container_contant {
        margin-bottom: 0px;
        height: auto !important;
    }

    #container_contant {
        margin-bottom: 0px;
    }

    .box-info-flex {
        padding: 50px 0px;
    }

    .header-info-item h3 {
        font-size: 28px !important;
    }

    .crm-systems-list {
        height: 200px;
        margin-bottom: -100px;
    }

    .work-steps-header {
        margin: 20px 0;
        font-size: 30px !important;
    }

    .row1-container {
        display: grid;
    }

    .red {
        order: 0;
    }

    .cyan {
        order: 1;
    }

    .blue {
        order: 2;
    }

    .box {
        height: auto;
        width: auto !important;
    }

    .box-info {
        padding: 50px 0;
    }

    .project-one, .project-two {
        flex-direction: column;
        align-items: center;
    }

    .header-info-item p.projects-list-header {
        font-size: 24px;
    }

    .about-us-text, .why-us-text {
        width: auto !important;
    }

    .about-us-box h2 {
        font-size: 25px !important;
    }

    .about-us-text p {
        font-size: 18px;
    }

    .about-us-box {
        margin-bottom: 50px;
    }

    .service-text {
        padding: 0px;
    }

    h1.service-heading {
        font-size: 45px;
    }

    h4.service-heading {
        font-size: 18px;
        margin-bottom: 0;
    }

    .service-text-blue, .service-text-white {
        padding: 20px;
    }

        .service-text-blue h2, .service-text-white h2 {
            font-size: 25px !important;
        }

        .service-text-blue p, .service-text-white p {
            font-size: 18px;
            line-height: normal;
            text-align: left;
        }

    .skill-info div {
        width: 100%;
    }

    .skill-list h3 {
        font-size: 25px !important;
    }

    .service-text-blue ul {
        text-align: left;
        line-height: normal;
    }

    .service-box {
        margin-bottom: 50px;
    }

    .about-us-text h3 {
        font-size: 20px !important;
        line-height: normal !important;
        margin-bottom: 15px;
    }

    .contacts-div {
        gap: 15px;
    }

    .contacts-us-img {
        height: 400px;
    }

    .about-us-text h5 {
        font-size: 18px !important;
    }

    .about-us-text {
        gap: 15px;
    }

    .contact-logo {
        padding: 0;
    }

    .column#main {
        padding: 0px;
    }

    .modal-content h3 {
        letter-spacing: normal;
        font-size: 20px !important;
        margin-bottom: 15px;
        line-height: normal !important;
    }

    .main-content {
        z-index: 10000;
        position: fixed;
    }

    .modal-open .main-content {
        z-index: 100;
    }

    .about-us-text .etc_h5 {
        font-size: 16px !important;
    }

    .main-content #sidebar-toggle .bar {
        background-color: #f8f9fa;
    }

    .carousel .nav-links .nav-link {
        font-size: 1.5rem;
    }

    .carousel .slides {
        display: none;
    }

    .carousel {
        flex-direction: column-reverse;
    }

        .carousel .nav-links {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0 28px;
            flex-wrap: wrap;
        }

        .carousel .overlays {
            width: auto;
            height: 200px;
            margin: 0 15px;
        }

            .carousel .overlays .bar {
                padding: 0 15px;
            }

    .bar p {
        width: auto;
        font-size: 20px;
        right: 0;
        left: 15px;
    }

    .search-input {
        flex-wrap: wrap;
    }

    .search-input .rs-dropdown, .search-input .rs-btn {
        width: 100% !important;
    }

    .category {
        width: 155px !important;
        height: 35px;
        font-size: 16px;
    }

    .categories {
        gap: 5px;
    }

    .app-card {
        width: 100% !important;
    }

    .app-name {
        font-size: 22px;
    }

    .app-developer {
        font-size: 18px;
    }

    .app-description, .app-installs {
        font-size: 14px;
    }

    .app-more {
        height: 35px;
        font-size: 16px;
    }

    .button-back-to-shop a {
        font-size: 20px;
    }

    .app-page-description {
        gap: 20px;
        flex-wrap: wrap;
    }

    .mySwiper2 .swiper-slide {
        height: auto;
    }

    .full-window_img {
        position: fixed;
        z-index: 7000;
        right: 15px;
    }

    .swiper-button-prev, .swiper-button-next {
        display: none !important;
    }

    .mySwiper2 .swiper-slide {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 10px;   
    }

    .mySwiper .swiper-slide, .mySwiper img, .full-window .mySwiper img, .full-window .mySwiper .swiper-slide {
        width: 100px !important;
        height: 70px;
    }

    .app-page-text h2 {
        font-size: 24px !important;
    }

    .app-page-text {
        font-size: 16px;
        gap: 5px;
    }

    a.app-page-button {
        height: 45px;
        font-size: 18px;
    }

    .main-text {
        font-size: 16px;
        margin-top: 20px;
    }

    .main-text h3 {
        margin-top: 20px;
        font-size: 24px !important;
    }

    .full-window .mySwiper2 .swiper-slide {
        padding-left: 0px;
        padding-right: 0px;
    }
}
